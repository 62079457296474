<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  />
</template>

<script>
import { BOverlay } from 'bootstrap-vue'

export default {
  name: 'SsoCallback',
  components: { BOverlay },
  data() {
    return {
      isLoading: false,
    }
  },
  created() {
    const { protocol, hostname } = window.location
    const redirectUri = `${protocol}//${hostname}/auth/login/ssoCallback&scope=openid+profile&nonce=asd`
    window.location.href = `${process.env.VUE_APP_SSO_URL}/oauth2/authorize?response_type=code+id_token+token&client_id=${process.env.VUE_APP_SSO_CLIENT_ID}&redirect_uri=${redirectUri}`
  },
}
</script>
